<template>
  <div>
    <v-row>
      <v-col>
        <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.smAndDown" />

        <v-col>
          <!-- app drawer -->

          <v-card
            :width="$vuetify.breakpoint.smAndUp ? '100%' : '100%'"
            tile
            :flat="$vuetify.breakpoint.mdAndDown"
            :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-tl-lg'"
            class="overflow-hidden"
          >
            <v-toolbar
              :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'transparent'"
              :flat="!$vuetify.breakpoint.smAndDown"
              :height="$vuetify.breakpoint.smAndDown ? 57 : 87"
            >
              <v-toolbar-title class="text-body-2 text-md-h6 font-weight-medium textColor1">
                <v-icon :large="!$vuetify.breakpoint.smAndDown" left>
                  {{ icons.mdiWallet }}
                </v-icon>
                Cari Hesap Bakiyeleri
              </v-toolbar-title>
            </v-toolbar>

            <!-- table -->

            <v-data-table
              clas="custom-table"
              v-model="selectedRows"
              :options.sync="options"
              :loading="loading"
              :headers="tableColumns"
              :height="dataTableHeight"
              :items="eventListTable"
              :items-per-page="1500"
              fixed-header
            >
              <template #[`item.BORC`]="{ item }">
                <div>{{ item.BORC | currency }}</div>
              </template>
              <template #[`item.ALACAK`]="{ item }">
                <div>{{ item.ALACAK | currency }}</div>
              </template>
              <template #[`item.BAKIYE`]="{ item }">
                <div class="font-weight-bold text-caption" :class="item.BAKIYE < '0,0' ? 'error--text' : null">
                  {{ item.BAKIYE | currency }}
                </div>
              </template>
              <template #[`item.actions`]="{ item }">
                <v-btn color="secondary" small @click="GoExtract(item)">Ekstreye Git</v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>

        <div>
          <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Counter from '@/components/Counter.vue'
import CustomerList from '@/components/CustomerList.vue'
import store from '@/store'
import orderStoreModule from '@/views/order/orderStoreModule'
import TrackingFilter from '@/views/orderTracking/tracking-list/TrackingFilter.vue'
import { useRouter } from '@core/utils'
import {
  mdiArrowLeft,
  mdiAttachment,
  mdiCamera,
  mdiCameraOff,
  mdiClose,
  mdiDelete,
  mdiEye,
  mdiFacebook,
  mdiFilter,
  mdiFilterOff,
  mdiFlash,
  mdiInstagram,
  mdiLinkedin,
  mdiMagnify,
  mdiPlusThick,
  mdiSearchWeb,
  mdiSquareEditOutline,
  mdiTwitter,
  mdiWallet,
  mdiWeb,
  mdiYoutube,
} from '@mdi/js'
import { postData } from '@utils'
import { onMounted, ref, watch } from '@vue/composition-api'
import VSnackbars from 'v-snackbars'
import Vue from 'vue'

const userData = JSON.parse(localStorage.getItem('userData'))

export default {
  components: {
    Counter,
    VSnackbars,
    CustomerList,
    TrackingFilter,
  },
  setup() {
    const data1 = ref('')
    const ORDER_APP_STORE_MODULE_NAME = 'app-order'
    const sendData = ref({})
    const { router } = useRouter()

    const GoExtract = item => {
      data1.value = item.ACCOUNT

      const data = {
        method: 'changeActiveCustomer',
        customer: item.ACCOUNT,
      }
      store
        .dispatch('postMethod', data)
        .then(response => {
          store.commit('PLASIYER_CUSTOMER', response.detail.customer)

          router.push({ name: 'extract-list' })
        })
        .catch(e => {
          loading.value = false
          Vue.swal({
            title: 'Hata',
            text: e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
    }

    // Register module
    if (!store.hasModule(ORDER_APP_STORE_MODULE_NAME)) {
      store.registerModule(ORDER_APP_STORE_MODULE_NAME, orderStoreModule)
    }

    const filterData = item => {
      idFilter.value = item.id || null
      siparisDurumFilter.value = item.siparisDurum || null
      odemeDurumFilter.value = item.odemeDurum || null
      startDateFilter.value = item.startDate || null
      endDateFilter.value = item.endDate || null
      minPriceFilter.value = item.minPrice || null
      maxPriceFilter.value = item.maxPrice || null

      let i = 0
      // eslint-disable-next-line no-restricted-syntax, no-unused-vars
      for (const [key, value] of Object.entries(item)) {
        if (value) {
          i += 1
        }
      }
      isFilterNumber.value = i

      sendData.value = { ...item }
    }
    const filter = ref({
      urunAdi: '',
    })
    const filterClear = () => {
      isFilterUserSidebarActive.value = false
      fotograftakiler.value = false
      stoktakiler.value = false
      aramaText.value = ''
      filter.value = {
        urunAdi: '',
      }
    }

    const hideOutStock1 = ref(0)
    const isFilterUserSidebarActive = ref(false)
    const isFilterNumber = ref(0)
    const dataListTable = ref([])
    const dataItem = ref({})
    const snackbar = ref([])
    const search = ref('')
    const product_title = ref('')
    const product_code = ref('')
    const status = ref(false)
    const isFilterTrackingSidebarActive = ref(false)
    const stoktakiler = ref(false)
    const fotograftakiler = ref(false)
    const aramaText = ref('')
    const tableColumns = [
      {
        text: 'Müşteri No',
        value: 'ACCOUNT',
      },
      {
        text: 'Müşteri',
        value: 'NAME1',
      },
      {
        text: 'Borç',
        value: 'BORC',
        align: 'right',
      },
      {
        text: 'Alacak',
        value: 'ALACAK',
        align: 'right',
      },
      {
        text: 'Bakiye',
        value: 'BAKIYE',
        align: 'right',
      },
      {
        text: '',
        value: 'actions',
        align: 'right',
        sortable: false,
        width: '150px',
      },
    ]
    if (window.screen.availWidth <= 600) {
      tableColumns.forEach(item => {
        delete item.filter
      })
    }
    const statusCustomer = ref(false)
    const kotaItems = ref([])
    const kotaDeger = ref()
    const totalEventListTable = ref(0)
    const kondisyonItems = ref([])
    const kondisyonDeger = ref()
    const grup = ref([])
    const groupName = ref([])
    const marka = ref([])
    const desen = ref([])
    const eventListTable = ref([])
    const ebat = ref([])
    const tipItems = ref([
      { text: 'Ön Ödemelli', value: 'O1P' },
      { text: 'Normal', value: 'O1' },
      { text: 'Bağlantılı (Yapım Aşamasında)', value: 'O9' },
    ])
    const tipDeger = ref()
    const delivery_date = store.state['app-order'].deliveryDate

    onMounted(() => {
      if (userData.role !== 'CUST') {
      }

      fetchEvents()

      if (userData.role === 'PLSYR' && store.state.plasiyerCustomer === null) {
        status.value = false
      } else if (userData.role === 'CUST') {
        status.value = true
        loading.value = true
        statusCustomer.value = true
      }
    })

    const condChange = val => {
      const paymcond = val.paymCond == '100' ? val.paymCond : `0${val.paymCond}`
      dataListTable.value.find(item => {
        if (item.id === val.id) {
          item.priceAll.forEach(el => {
            if (el.paymcond == paymcond) {
              if (el.plant == '01') {
                item.product_price_01 = el.price1
              } else if (el.plant == '02') {
                item.product_price_02 = el.price1
              }
            }
          })
        }
      })
    }

    watch(
      () => stoktakiler.value,
      () => {
        if (stoktakiler.value == true) {
          hideOutStock1.value = 1
        } else {
          hideOutStock1.value = 0
        }

        console.log('değer= ' + hideOutStock1.value)

        // refreshBasket()
      },
    )

    const fetchEvents = () => {
      const query = {}
      const method = 'listCustomerBalanceSALDEPT'
      store
        .dispatch('fetchMethod', { query, method })
        .then(response => {
          console.log(response)
          eventListTable.value = response.detail
          //totalEventListTable.value = Number(response.response.pagination.totalRec)
          loading.value = false
        })
        .catch(e => {
          loading.value = false
          Vue.swal({
            title: 'Hata',
            text: e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
    }

    const kotaChange = () => {
      postData({
        method: 'setMemberKota',
        limitnum: kotaDeger.value,
        delivery_date,
      }).then(response => {
        const validFromRaw = response.detail.validFrom.slice(0, 10)
        const validFromSplit = validFromRaw.split('.')
        const validFrom = `${validFromSplit[2]}-${validFromSplit[1]}-${validFromSplit[0]}`

        const validUntilRaw = response.detail.validUntil.slice(0, 10)
        const validUntilSplit = validUntilRaw.split('.')
        const validUntil = `${validUntilSplit[2]}-${validUntilSplit[1]}-${validUntilSplit[0]}`

        store.commit('app-order/DELIVERY_DATE_MAX', validUntil)
        store.commit('app-order/DELIVERY_DATE_MIN', validFrom)
      })
    }

    const kondisyonChange = () => {
      postData({
        method: 'setMemberCondition',
        condition: kondisyonDeger.value,
      }).then(response => {})
    }

    const tipChange = () => {
      postData({
        method: 'changeOrderType',
        orderType: tipDeger.value,
      }).then(response => {
        // kondisyonItems
        store.commit('PAYMENT_TYPE', tipDeger.value)
        kondisyonItems.value = []
        kondisyonDeger.value = null
        for (const [key, value] of Object.entries(response.detail)) {
          kondisyonItems.value.push({
            text: value,
            value: key,
          })
        }
      })
    }
    const searchData = () => {
      loading.value = true
    }

    const refreshCustomer = () => {
      //loading.value = false
      kondisyonItems.value = []
      kotaItems.value = []
      kondisyonDeger.value = ''
      kotaDeger.value = ''
      tipDeger.value = ''
      statusCustomer.value = false
      //plasyirFn()
    }

    watch(
      () => store.state.plasiyerCustomer,
      () => {},
    )

    const totalDataListTable = ref(0)
    const loading = ref(true)
    const options = ref({
      page: 1,
      itemsPerPage: 1500,
      sortBy: ['id'],
      sortDesc: [true],
      groupBy: [],
      groupDesc: [],
      mustSort: false,
      multiSort: false,
    })
    const dataTotalLocal = ref([])
    const selectedRows = ref([])
    const count = ref(1)
    const selected = item => {
      // const snackbarData = dataListTable.value.find(i => i.id === item.uid)
      // if (item.number != 0) {
      //   // count.value = restaurant.value
      //   snackbar.value.push({
      //     color: null,
      //     message: {
      //       status: true,
      //       plant: item.plantId === '01' ? 'Tekirdağ' : 'Konya',
      //       color: item.plantId === '01' ? 'secondary' : 'warning',
      //       product: snackbarData.product_title,
      //       count: item.number,
      //       birim: 'Ad',
      //     },
      //   })
      // } else {
      //   snackbar.value.push({
      //     color: 'error',
      //     message: {
      //       status: false,
      //       plant: item.plantId === '01' ? 'Tekirdağ' : 'Konya',
      //       color: item.plantId === '01' ? 'secondary' : 'warning',
      //       product: snackbarData.product_title,
      //       count: item.number,
      //       birim: 'Ad',
      //     },
      //   })
      // }
    }
    const itemRowBackground = item => (item.selected ? 'style-1' : 'style-2')

    /*
      watch(
        () => store.getters['app-order/doneBasket'],
        () => {

          console.log('x' + store.getters.value)

          // refreshBasket()
        },
      )
      */

    const statusFind = (arr, value, select) => store.state.tanimlar[arr].find(item => item.value === value)[select]

    return {
      statusFind,
      userData,
      ebat,
      dataItem,
      dataListTable,
      tableColumns,
      totalDataListTable,
      loading,
      itemRowBackground,
      selected,
      sendData,
      grup,
      groupName,
      marka,
      options,
      dataTotalLocal,
      selectedRows,
      GoExtract,

      count,
      snackbar,
      search,
      product_title,
      product_code,
      searchData,
      status,
      statusCustomer,
      kotaDeger,
      kotaChange,
      kotaItems,
      kondisyonItems,
      kondisyonDeger,
      isFilterUserSidebarActive,
      kondisyonChange,
      tipItems,
      filterData,
      data1,
      eventListTable,
      desen,
      tipDeger,
      tipChange,
      filterClear,
      totalEventListTable,
      stoktakiler,
      fotograftakiler,
      aramaText,
      refreshCustomer,
      filter,
      hideOutStock1,
      condChange,
      isFilterTrackingSidebarActive,
      isFilterNumber,

      // icons
      icons: {
        mdiCamera,
        mdiDelete,
        mdiLinkedin,
        mdiTwitter,
        mdiCameraOff,
        mdiAttachment,
        mdiFlash,
        mdiInstagram,
        mdiYoutube,
        mdiEye,
        mdiFacebook,
        mdiWeb,
        mdiFilter,
        mdiFilterOff,
        mdiPlusThick,
        mdiSquareEditOutline,
        mdiClose,
        mdiMagnify,
        mdiArrowLeft,
        mdiSearchWeb,
        mdiWallet,
      },
    }
  },
  data() {
    return {
      panel: null,
      date: null,
      modal: false,
      filterStatus: false,
      dataTableHeight: '',
      height: {
        system: 0,
        top: this.$vuetify.breakpoint.smAndDown ? 0 : 0,
        footer: this.$vuetify.breakpoint.smAndDown ? 0 : 0,
      },
    }
  },

  watch: {
    panel(val) {
      if (val === 0) {
        setTimeout(() => {
          this.height.top = 250
          this.onResize()
        }, 300)
      } else {
        this.height.top = 48
        this.onResize()
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    addBasket(plant, product, id, count, birim) {
      store
        .dispatch('app-order/fetchAddToBasket', {
          method: 'addToBasket',
          uid: id,
          qty: count,
          plant,
        })
        .then(response => {
          if (response.error) {
            Vue.swal({
              title: 'Hata',
              html: response.detail.msg,
              icon: 'error',
              timer: 2000,
              background: '#FF4C51',

              timerProgressBar: true,
              showConfirmButton: false,
            })
          } else {
            this.snackbar.push({ product, count, birim })
          }
        })
    },
    async addToBasket(plants, product, id, count, birim) {
      //değişen kısım   if (plants.length === 1)
      if (plants.length !== 1) {
        this.addBasket(plants[0].plant, product, id, count, birim)

        return
      }

      const plantsMap = new Map(plants.map(object => [object.plant, object.stext]))

      const { value: plant } = await Vue.swal({
        icon: 'info',
        title: 'Fabrika Seçimi',
        text: 'Lütfen ürünü eklemek istediğiniz fabrikayı seçiniz',
        input: 'select',
        inputOptions: plantsMap,
      })

      if (plant) {
        this.addBasket(plant, product, id, count, birim)
      }
    },
    onResize() {
      const val = this.$vuetify.breakpoint.xsOnly ? 120 : 90
      this.dataTableHeight = window.innerHeight - val - this.height.top - this.height.footer - 1
    },
  },
}
</script>

<style lang="scss">
@import '~@core/layouts/styles/_variables';
.search-mobil {
  position: fixed;
  z-index: 5;
  width: 100%;
  left: 0;
  top: 70px;
}
.textColor {
  color: white;
}

.v-select__selection {
  color: grey !important ;
}

.theme--light.v-select .v-select__selection--comma {
  color: rgba(255, 255, 255, 0.87);
}

.search-mobil-plasiyer {
  position: fixed;
  z-index: 5;
  width: 100%;
  left: 0;
  top: 135px !important;
}
.text-plain {
  font-size: 10px;
  line-height: 1;
  padding-bottom: 2px;
}
.po-0 {
  .v-toolbar__content {
    padding: 0px 18px 0px 15px !important;
  }
}
// .v-data-table__wrapper::-webkit-scrollbar {
//   width: 8px;
//   height: 8px;
//   background-color: #3b355a;
// }
// .v-data-table__wrapper::-webkit-scrollbar-thumb {
//   background-color: #0083e8;
// }
// .v-application.theme--dark .app-content-container .v-data-table-header .plant_konya {
//   border-bottom: 1px solid #ffb400 !important ;
// }
// .v-application.theme--dark .app-content-container .v-data-table-header .plant_tekirdag {
//   border-bottom: 1px solid #0083e8 !important ;
// }

.min-width-400 {
  min-width: 360px;
}
.style-1 {
  background-color: rgba(255, 255, 255, 0.05);
}
.style-2 {
  // background-color: rgb(114, 114, 67);
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
    min-height: auto !important;
  }
  .theme--light tr {
    background-color: #fff !important;
  }
  .theme--dark tr {
    background-color: #3b355a !important;
  }
  .v-data-table__empty-wrapper {
    td {
      position: relative !important;
    }
  }
  tr {
    position: relative;
    padding: 15px 0;

    border-bottom: thin solid rgba(231, 227, 252, 0.14);

    td:nth-child(1) {
      position: absolute;

      .v-data-table__mobile-row__header {
        display: none;
      }
    }
    td:nth-child(2),
    td:nth-child(3) {
      padding-top: 5px !important;
      .v-data-table__mobile-row__header {
        display: none;
      }
      .v-data-table__mobile-row__cell {
        padding-left: 70px;
        text-align: left !important;
      }
    }
    td:nth-child(4) {
      display: flex;
      justify-content: flex-end;
      padding-top: 30px !important;
      .v-data-table__mobile-row__header {
        display: none;
      }
    }
    td:nth-child(5) {
      display: flex;
      justify-content: flex-end;
      .v-data-table__mobile-row__header {
        display: none;
      }
    }
  }
  .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:last-child,
  .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:last-child {
    border: none !important;
  }
}
.v-tooltip__content {
  opacity: 1 !important;
  box-shadow: 5px 5px 10px 0px rgb(0 0 0 / 30%) !important;
}
.paymCond-hidden {
  display: none !important;
}
.rounded-kor {
  .v-input__slot {
    padding: 0 !important;
    padding-right: 5px !important;
    padding-left: 10px !important;
  }
}
.custom-table tbody tr {
  height: 450px !important;
}

.v-select-height .v-select__slot {
  height: 40px !important; /* Yükseklik değeri */
}
.custom-select .v-select__selection {
  color: black !important;
}

.custom-select .v-select__selection--comma {
  color: black !important;
}

.custom-select .v-select__selection--disabled {
  color: black !important;
}
.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  background: rgba(255, 255, 255, 0.03);
}
.textColor1 {
  color: black !important;
}
.small-font {
  font-size: 11px; /* veya istediğiniz boyutu belirleyebilirsiniz */
}
.arama {
  font-size: 15px;
  color: #1d1717;
}
.custom-label .v-label {
  font-size: 12px; /* İstediğiniz küçük font boyutunu burada belirtebilirsiniz */
}
.theme--light.v-sheet .v-card__title {
  color: #ffffff !important;
}
.custom-link {
  text-decoration: none;
  /* Diğer stil ayarlarınızı burada belirtebilirsiniz */
}

.v-application.theme--light .v-btn--is-elevated {
  box-shadow: 0 0px 0px -4px rgba(94, 86, 105, 0.42) !important;
}
</style>
